.teach-cards{
    width: 30%;
    margin: 10px;
}

.video{
    height: 50% !important;
    width: 50% !important;
}

@media only screen and (max-width:950px){
    .video{
        width: 80% !important;
        height: 80% !important;
    }

}




@media only screen and (max-width: 780px){
    .video{
        width: 70% !important;
        height: 70% !important;
    }
    .teach-cards{
        width: 80%;
        
    }
    
    .cards{
        justify-content: center;
    }
}

@media only screen and (max-width:500px){
    
    .video{
        width: 100% !important;
        height: 100% !important;
    }
}